<template>
    <div>
        <b-loading v-model="isLoading" :is-full-page="true" />

        <section class="box">
            <div v-if="addKw == false">
                <div class="d-flex mb-2 justify-content-between">
                    <div class="d-flex">
                        <b-button type="is-warning" class="mb-2 mb-md-0 ml-md-2 d-flex" @click="addKw = !addKw">
                            <i class="fas fa-plus-square mr-2" />Ajouter des MC
                        </b-button>
                    </div>
                </div>

                <SitePreorderTable
                    :pre-orders-list="pageData"
                    @updatePreOrder="updatePreOrder"
                    @bulkSetPartnerAvailability="bulkSetPartnerAvailability"
                    @setWhitePagePreOrders="setWhitePagePreOrders"
                    @setRedactorPreOrders="setRedactorPreOrders"
                    @duplicatePreOrders="duplicatePreOrders"
                    @deletePreOrders="deletePreOrders"
                    @launchPreOrders="launchPreOrders"
                    @loadAsyncData="getData"
                />
            </div>

            <div v-else>
                <b-button type="is-warning" class="mb-2 mb-md-0 ml-md-2 d-flex" @click="backAddKw">
                    <i class="fas fa-arrow-left mr-2" />Retour
                </b-button>
                <div class="d-flex w-100">
                    <div class="p-2 w-100 mx-2">
                        <p class="font-weight-bold">Ajout des MC à travailler</p>
                        <!--            <div class="d-flex flex-wrap">
                          <div class="w-25" v-for="i in kw" :key="i.id">
                            <input type="checkbox" :value="i" v-model="checkKW"/>
                            <label>{{ i.keyword }}</label>
                            <br/>
                          </div>
                        </div>-->
                        <div class="d-flex justify-content-between">
                            <div class="d-flex">
                                <b-input
                                    v-model="randomNumber"
                                    type="number"
                                    class="mr-2"
                                    placeholder="Nombre à sélectionner"
                                />
                                <b-button type="is-warning" @click="selectKeywordRandom"> Sélectionner</b-button>
                            </div>
                            <b-button type="is-warning" @click="startWork">
                                Valider ({{ checkKW.length + selectedCustomKeywords.length }})
                            </b-button>
                        </div>
                        <b-table :data="campaigns" :striped="true" custom-detail-row detailed class="table-striped">
                            <b-table-column field="id" sortable label="Id">
                                <div slot="default" slot-scope="props">
                                    {{ props.row.id }}
                                </div>
                            </b-table-column>

                            <b-table-column field="url" sortable label="Url">
                                <div slot="default" slot-scope="props">
                                    {{ props.row.url }}
                                    <b-tooltip
                                        v-if="checkAlreadyPreorder(props.row.id)"
                                        :label="`Cet accompagnement a déjà une précommande pour ce site !`"
                                        position="is-right"
                                    >
                                        <i class="fas fa-exclamation-triangle" />
                                    </b-tooltip>
                                </div>
                            </b-table-column>

                            <b-table-column field="nb_keywords" sortable label="Nombre Mot Clé">
                                <div slot="default" slot-scope="props">
                                    {{ countKeywordCampaign(props.row) }}
                                </div>
                            </b-table-column>

                            <template slot="detail" slot-scope="props" style="height: auto !important">
                                <tr style="height: auto !important">
                                    <td colspan="100%" class="p-2" style="height: auto !important">
                                        <div v-for="i in props.row.urls" :key="i.id">
                                            <div v-for="y in i.keywords" :key="y.id" class="mr-2">
                                                <input
                                                    v-model="checkKW"
                                                    type="checkbox"
                                                    :value="{
                                                        id: y.id,
                                                        campaignId: props.row.id,
                                                        keyword: y.mot_cle,
                                                        pre_orders_count: props.row.pre_orders_count
                                                    }"
                                                />
                                                <label>{{ y.mot_cle }}</label>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </b-table>
                    </div>
                    <div class="p-2 w-50 mx-2">
                        <p class="font-weight-bold fs-5">Ajout manuel</p>
                        <textarea
                            v-model="customKW"
                            class="form-control"
                            style="height: 75% !important"
                            placeholder="Séparer les mots-clés par une virgule"
                            @input="countSelectedCustomKeyword"
                        />
                    </div>
                </div>
                <div class="text-center">
                    <b-button class="mt-3 btn" type="is-warning" @click="startWork">
                        Valider ({{ checkKW.length + selectedCustomKeywords.length }})
                    </b-button>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import SitePreorderTable from '../../../components/Admin/Sites/SitePreorderTable'

    export default {
        name: 'SitePreorder',
        components: { SitePreorderTable },
        data() {
            return {
                isLoading: false,
                addKw: false,
                pageData: {},
                sites: [],
                groups: [],
                campaignPreOrdersSites: {},
                whitepageCreditsRemaining: 0,
                selectSiteModal: false,
                addKWModal: false,
                settingsModal: false,
                settings: {
                    id: 0,
                    articles_count: 0,
                    brief: '',
                    words_count: 0,
                    lang: 'fr'
                },
                campaigns: [],
                kw: [],
                customKW: null,
                checkKW: [],
                selectedKeywords: [],
                selectedCustomKeywords: [],
                randomNumber: 0
            }
        },
        computed: {
            computeKWList() {
                let list = []
                this.checkKW.forEach(e => {
                    list.push(e)
                })
                if (this.customKW) {
                    let customKWList = this.customKW.split(/[\n,]/)
                    customKWList.forEach(e => {
                        list.push(e)
                    })
                }

                return list
            }
        },
        created() {
            this.isLoading = true
            this.getData()
            this.loadKW()
        },
        methods: {
            getData() {
                this.isLoading = true

                this.axios
                    .get(`/admin/site-preorder/preorder/${this.$route.query.id}`)
                    .then(response => {
                        this.pageData = response.data.preorders

                        //this.formatPreOrders()
                        //this.fetchSettings();
                    })
                    .catch(error => console.error(error))
                    .finally(() => (this.isLoading = false))
            },
            backAddKw() {
                this.addKw = !this.addKw
                this.getData()
            },
            countKeywordCampaign(campaign) {
                let counts = 0

                for (const url of campaign.urls) {
                    for (const urlElement of url.keywords) {
                        counts = counts + 1
                    }
                }

                return counts
            },
            countSelectedCustomKeyword() {
                let customKWList = this.customKW.split(/[\n,]/)
                this.selectedCustomKeywords = customKWList
            },
            checkAlreadyPreorder(campaignId) {
                let campaign = this.campaigns.find(element => element.id == campaignId)
                let preorder = campaign.pre_orders.find(element => element.site_id == this.$route.query.id)
                if (preorder != null) {
                    return true
                } else {
                    return false
                }
            },
            selectKeywordRandom() {
                let keywordsArray = []
                for (const keywordsArrayElement of this.kw) {
                    if (!this.checkAlreadyPreorder(keywordsArrayElement.campaignId))
                        keywordsArray.push(keywordsArrayElement)
                }

                let i = 0

                while (i < this.randomNumber) {
                    let random = Math.floor(Math.random() * keywordsArray.length)

                    let campaign = this.campaigns.find(element => element.id == keywordsArray[random].campaignId)

                    if (!campaign.selectedKW) {
                        this.checkKW.push(keywordsArray[random])
                        this.selectedKeywords.push(keywordsArray[random])
                        campaign.selectedKW = true
                    }

                    i++
                }
            },
            launchPreOrders(preOrders) {
                const cannotBeLaunch = []

                if (this.confirmModal) {
                    this.confirmModal = false
                }

                preOrders.forEach(preOrder => {
                    if (!this.canBeLaunched(preOrder)) {
                        cannotBeLaunch.push(preOrder)
                    }
                })

                if (cannotBeLaunch.length) {
                    this.$buefy.snackbar.open({
                        message: `Vous ne pouvez pas lancer la précommande. Il est nécessaire d'enregistrer un site, d'indiquer par quel moyen passer la précommande, et de remplir les champs "H1" et "Meta Title".`,
                        position: 'is-bottom',
                        type: 'is-danger',
                        duration: 4500
                    })

                    return
                }

                this.isLoading = true

                this.axios
                    .post(`/admin/site-preorder/launch`, {
                        _method: 'PATCH',
                        ids: preOrders.map(preOrder => preOrder.id)
                    })
                    .then(() => {
                        preOrders.forEach(preOrder => {
                            preOrder.launched_at = 1
                            preOrder.status = 'in_progress'
                        })

                        this.snackNotif('Précommande(s) lancée(s).', 'is-success')
                    })
                    .catch(error => console.error(error))
                    .finally(() => {
                        this.isLoading = false
                    })
            },

            bulkSetPartnerAvailability(params) {
                this.isLoading = true

                this.axios
                    .post(`/admin/site-preorder/preorders/partners`, {
                        ids: params.ids,
                        partner_availability: params.partner_availability,
                        _method: 'PATCH'
                    })
                    .then(() => {
                        this.preorders.data = this.preorders.data.map(preorder => {
                            if (params.ids.includes(preorder.id)) {
                                preorder.partner_availability = params.partner_availability
                            }

                            return preorder
                        })

                        this.snackNotif('Précommande(s) mise(s) à jour.', 'is-success')
                    })
                    .catch(error => console.error(error))
                    .finally(() => (this.isLoading = false))
            },

            updatePreOrder(preOrder) {
                this.isLoading = true

                this.axios
                    .post(`/admin/site-preorder/preorders/${preOrder.id}`, {
                        site_id: preOrder.site_id,
                        group_id: preOrder.group_id,
                        partner_availability: preOrder.partner_availability,
                        tag_title: preOrder.tag_title,
                        meta_title: preOrder.meta_title,
                        type: preOrder.type,
                        _method: 'PATCH'
                    })
                    .then(() => {
                        this.formatPreOrders()

                        this.snackNotif('Précommande(s) mise(s) à jour.', 'is-success')
                    })
                    .catch(error => console.error(error))
                    .finally(() => (this.isLoading = false))
            },

            deletePreOrders(preOrders) {
                this.isLoading = true

                this.axios
                    .post(`/admin/site-preorder`, {
                        _method: 'DELETE',
                        ids: preOrders.map(row => row.id)
                    })
                    .then(() => {
                        this.pageData = this.pageData.filter(preOrder => !preOrders.includes(preOrder))

                        this.formatPreOrders()

                        this.snackNotif('Précommande(s) supprimée(s).', 'is-success')
                    })
                    .catch(error => console.error(error))
                    .finally(() => (this.isLoading = false))
            },

            setWhitePagePreOrders(preOrders) {
                this.isLoading = true

                this.axios
                    .patch(`/admin/site-preorder/whitepage`, {
                        ids: preOrders.map(row => row.id)
                    })
                    .then(response => {
                        this.pageData = this.pageData.map(preorder => {
                            var ids = response.data.preorders.map(row => row.id)
                            if (ids.includes(preorder.id)) {
                                preorder = response.data.preorders[ids.indexOf(preorder.id)]
                            }

                            return preorder
                        })
                        this.formatPreOrders()

                        this.snackNotif('WhitePage ajoutée(s).', 'is-success')
                    })
                    .catch(error => console.error(error))
                    .finally(() => (this.isLoading = false))
            },

            setRedactorPreOrders(preOrders) {
                this.isLoading = true

                this.axios
                    .patch(`/admin/site-preorder/redactor`, {
                        ids: preOrders.map(row => row.id)
                    })
                    .then(response => {
                        this.pageData = this.pageData.map(preorder => {
                            var ids = response.data.preorders.map(row => row.id)
                            if (ids.includes(preorder.id)) {
                                preorder = response.data.preorders[ids.indexOf(preorder.id)]
                            }

                            return preorder
                        })
                        this.formatPreOrders()

                        this.snackNotif('Redactor ajoutée(s).', 'is-success')
                    })
                    .catch(error => console.error(error))
                    .finally(() => (this.isLoading = false))
            },

            duplicatePreOrders(preOrders) {
                this.isLoading = true

                this.axios
                    .patch(`/admin/site-preorder/duplicate`, {
                        ids: preOrders.map(row => row.id)
                    })
                    .then(() => {
                        this.getData()
                        this.formatPreOrders()

                        this.snackNotif('Précommande(s) dupliquée(s).', 'is-success')
                    })
                    .catch(error => console.error(error))
                    .finally(() => (this.isLoading = false))
            },

            canBeLaunched(preOrder) {
                if (!preOrder.type) {
                    return false
                }

                if (!preOrder.meta_title) {
                    return false
                }

                if (!preOrder.tag_title) {
                    return false
                }

                if (!preOrder.site_id) {
                    return false
                }

                if (preOrder.launched_at === 1) {
                    return false
                }

                return true
            },

            formatPreOrders() {
                this.pageData = this.pageData.map(preOrder => {
                    preOrder.launched_at = preOrder.launched_at ? 1 : 0
                    preOrder.partner_availability = preOrder.partner_availability ? true : false

                    return preOrder
                })
            },

            loadAsyncData(data) {
                var field = data.sortField
                var route

                if (data.sortOrder === 'desc') {
                    field = '-' + data.sortField
                }

                var params = {
                    page: data.page,
                    include: data.includes,
                    sort: field
                }

                if (data.filters && data.filters.length > 0) {
                    data.filters.forEach(element => {
                        params[`filter[${element.key}]`] = element.value
                    })
                }

                route = data.path

                let perPage = data.per_page ? data.per_page : 20
                perPage = route.includes('status') ? `&per_page=${perPage}` : `?per_page=${perPage}`

                this.axios
                    .get(route + perPage, {
                        params
                    })
                    .then(response => {
                        this.preorders = response.data.preorders
                    })
                    .catch(error => {
                        this.snackNotif(error.response.data.message, 'is-danger')
                        console.error(error)
                    })
                    .finally(() => {
                        data.callback()
                    })
            },
            loadKW() {
                this.isLoading = true
                this.axios
                    .get(`/admin/site-preorder/campaign/keywords`)
                    .then(response => {
                        this.campaigns = response.data.campaigns
                        for (const campaign of response.data.campaigns) {
                            for (const url of campaign.urls) {
                                for (const keyword of url.keywords) {
                                    this.kw.push({
                                        id: keyword.id,
                                        campaignId: campaign.id,
                                        keyword: keyword.mot_cle,
                                        pre_orders_count: campaign.pre_orders_count
                                    })
                                }
                            }
                        }
                    })
                    .catch(error => console.error(error))
                    .finally(() => {
                        this.isLoading = false
                    })
            },
            startWork() {
                let keywords = []
                this.computeKWList.forEach(element => {
                    let keywordTemp
                    keywordTemp = {
                        keyword: element,
                        posts_count: 1
                    }
                    keywords.push(keywordTemp)
                })
                let params = {
                    words_count: 500,
                    keywords: keywords,
                    lang: 'fr',
                    brief: 'Rédiger un article correspondant au titre fourni. Utiliser un H2 contenant le mot-clé ###. Vous pouvez utiliser une liste à puces. Rédaction sans duplication et sans faute.',
                    site_id: this.$route.query.id
                }
                this.isLoading = true
                this.axios
                    .post(`/admin/site-preorder/preorder`, params)
                    .then(response => console.log(response))
                    .catch(error => console.log(error))
                    .finally(() => {
                        this.isLoading = false
                        this.addKw = false
                        this.checkKW = []
                        this.customKW = null
                        this.getData()
                    })
            }
        }
    }
</script>
